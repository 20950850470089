/*eslint-disable*/
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import { useTranslation } from 'react-i18next'; 

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function ContactUs() {
  const [t] = useTranslation();
  const defaultProps = {
    center: [ 41.8532927, 0.9551982],
    zoom: 12
  };
  return (
    <div className="section-project">
        <h2 className="title">{t("landing-contact-title")}</h2>
      <MapContainer center={defaultProps.center} zoom={12} scrollWheelZoom={false} style={{width:"100%", height:400, alignSelf:"center"}}>
        <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={defaultProps.center}>
        </Marker>
    </MapContainer>
    <br/>
    <br/>
    <Container>
          <Row>
            <Col md="4">
            <Card style={{backgroundColor:"#efefef"}}  className="text-center">
                <CardBody>
                  <h6 className="card-category">
                    {t("landing-schedule-title")}
                  </h6>
                  <div className="card-icon">
                    <i className="nc-icon nc-calendar-60" />
                  </div>
                  <p className="card-description"
                      dangerouslySetInnerHTML={{
                        __html: t("landing-schedule-desc"),
                      }}/>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
            <Card style={{backgroundColor:"#efefef"}}  className="text-center">
                <CardBody>
                  <h6 className="card-category">
                    {t("landing-contactPhone-title")}
                  </h6>
                  <div className="card-icon">
                    <i className="nc-icon nc-chat-33" />
                  </div>
                  <p className="card-description"dangerouslySetInnerHTML={{
                        __html: t("landing-contactPhone-desc"),
                      }}/>
                  <CardFooter>
                  <CardFooter>
                    <Button className="btn-magnify btn-round" color="danger" href="tel:34973459003">
                      <i className="fa fa-phone"></i>
                      {t("landing-contactPhone-button")}
                    </Button>
                  </CardFooter>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card style={{backgroundColor:"#efefef"}}  className="text-center">
                <CardBody>
                  <h6 className="card-category">
                    {t("landing-contactAddress-title")}
                  </h6>
                  <div className="card-icon">
                    <i className="nc-icon nc-pin-3" />
                  </div>
                  <p className="card-description"
                  dangerouslySetInnerHTML={{
                    __html: t("landing-contactAddress-desc"),
                  }}/>
                  <CardFooter>
                    <Button className="btn-magnify btn-round" color="danger" target="blank" href="https://goo.gl/maps/4pfuUU4qXxJnJWUh9">
                      <i className="fa fa-map-signs"></i>
                      {t("landing-contactAddress-button")}
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default ContactUs;

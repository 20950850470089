import React from "react";

// reactstrap components

// core components

function SettingsHeader() {
  return (
    <>
      <div
        className="page-header page-header-small settings-background"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/places/ponttrencat.jpeg").default +
            ")",
        }}
      >
        <div className="filter" />
      </div>
    </>
  );
}

export default SettingsHeader;

import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
// reactstrap components
import {Button,} from "reactstrap";


function PdfRenderer(props) {
    const [t] = useTranslation();
    const files = {
        menu: "https://firebasestorage.googleapis.com/v0/b/menumaker-9e055.appspot.com/o/menu.pdf?alt=media&token=60c7808a-09f4-4c76-a217-90bd7fa8e669",
        carta: "https://firebasestorage.googleapis.com/v0/b/menumaker-9e055.appspot.com/o/carta-compressed.pdf?alt=media&token=fe9aadeb-c42a-47e0-8b5f-f52100dde443",
        bodega: "https://firebasestorage.googleapis.com/v0/b/menumaker-9e055.appspot.com/o/carta-vins.pdf?alt=media&token=d8298b46-4948-42dc-8934-a5dc5f33d986",
        cafe: "https://firebasestorage.googleapis.com/v0/b/menumaker-9e055.appspot.com/o/entrepans-plats-combinats-compressed.pdf?alt=media&token=29dbbd38-c387-4caa-9234-f55685800ba1",
        festaMajor: "https://firebasestorage.googleapis.com/v0/b/menumaker-9e055.appspot.com/o/FestaMajor_22.pdf?alt=media&token=2f98104f-cc06-41a6-8bc6-eb7534365bbb"
    }
    const href = {
        menu: "/menu",
        carta: "/la-carta",
        bodega: "/bodega",
        cafe: "/cafe",
        festaMajor: "/festaMajor",
    }
    const file = files[props.selected];
    const friday19August = 1660867200000;
    const friday22August = 1661126400000;
    const now = (new Date()).valueOf();
    const isFestMajor = now > friday19August && now < friday22August;
    return (
        <>
            <ColorNavbar fixed/>
            <div data-key={file}
            >
                <iframe
                    data-file={file}
                    src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + encodeURIComponent(file)}
                    width="100%"
                    height={window.innerHeight - 140}
                    style={{marginTop: 70, marginBottom: 60}}
                >
                    <a href={file}>Abrir aquí</a>
                </iframe>
            </div>

            <div
                style={{
                    overflow: "auto",
                    whiteSpace: "nowrap",
                    width: "100%",
                    padding: 10,
                    paddingBottom: 20,
                    position: "fixed",
                    zIndex: 10,
                    bottom: 0,
                    backgroundColor: "#E3E3E3CF",
                }}
            >
                {isFestMajor &&
                    <a href={href["festaMajor"]}>
                        <Button
                            className="btn-round card-link mx-2"
                            color={props.selected === "festaMajor" ? "" : "danger"}
                        >
                            {t("carta-festa-major-title")}
                        </Button>
                    </a>
                }
                <a href={href["menu"]}>

                    <Button
                        className="btn-round card-link mx-2"
                        color={props.selected === "menu" ? "" : "danger"}
                    >
                        {t("carta-menu-title")}
                    </Button>
                </a>
                <a href={href["carta"]}>

                    <Button
                        className="btn-round card-link mx-2"
                        color={props.selected === "carta" ? "" : "danger"}
                    >
                        {t("carta-carta-title")}
                    </Button>
                </a>

                {/*<Button*/}
                {/*    className="btn-round card-link"*/}
                {/*    color={props.selected === "nadal" ? "" : "success"}*/}
                {/*    onClick={() => history.push("/nadal")}*/}
                {/*>*/}
                {/*    <i className="fas fa-tree mr-2"/>*/}
                {/*    {t("carta-nadal-takeaway-title")}*/}
                {/*</Button>*/}
                <a href={href["bodega"]}>
                    <Button
                        className="btn-round card-link mx-2"
                        color={props.selected === "bodega" ? "" : "danger"}
                    >
                        {t("carta-winery-title")}
                    </Button>
                </a>
                <a href={href["cafe"]}>

                    <Button
                        className="btn-round card-link"
                        color={props.selected === "cafe" ? "" : "danger"}
                    >
                        {t("carta-cafe-title")}
                    </Button>
                </a>
            </div>
        </>
    );
}

export default PdfRenderer;

/*

            <table
                style={{
                    styleborder: 0,
                    cellspacing: 0,
                    cellpadding: 0,
                    marginTop: 70,
                    width: "100%",
                    height: window.innerHeight,
                }}
            >
                <iframe
                    src={url}
                    style={{
                        width: "100%",
                        height: window.innerHeight + 500,
                        marginwidth: 0,
                        frameborder: 0,
                    }}
                ></iframe>
            </table>
            */

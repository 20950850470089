import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function Header() {
  let pageHeader = React.createRef();
  const [t] = useTranslation();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/frontView.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title font-weight-normal">
                {t("landing-title1")}
              </h1>
              <h3 className="description">{t("landing-desc1")}</h3>
              <br />
              <Button
                className="btn-round btn-magnify mt-2"
                color="danger"
                href="/carta"
              >
                <i className="fa fa-cutlery mr-1" />
                {t("landing-online-menubutton")}
              </Button>
              <br />
              <Button
                className="btn-round btn-magnify mt-2"
                color="neutral"
                href="https://direct-book.com/properties/hostalrestauranteromadirect"
                target="_blank"
                rel="noopener"
              >
                <i className="fa fa-bed mr-1" />
                {t("landing-bookhostal-button")}
              </Button>
              {/*<br />*/}
              {/*<Button*/}
              {/*    className="btn-round btn-magnify mt-2"*/}
              {/*    color="success"*/}
              {/*    href="/nadal"*/}
              {/*>*/}
              {/*  <i className="fas fa-tree mr-1" />*/}
              {/*  {t('nadal_button')}*/}
              {/*</Button>*/}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Header;

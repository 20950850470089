import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Button,
  CardBody,
  CardFooter,
  Container,
  Card,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ImgCard = ({ img }) => (
  <Card style={{ flex: 3, flexBasis: 350 }} className="m-2 ">
    <img
      style={{ borderRadius: 10 }}
      alt="..."
      className="img"
      src={img.default}
    />
  </Card>
);

function Main() {
  const [startDate, setStartDate] = React.useState(new Date());
  const [t] = useTranslation();
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
    };
  });

  return (
    <>
      <div className="main">
        <div className="section section-dark">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{t("restaurant-title")}</h2>
              </Col>
              <Col className="ml-auto mr-auto " md="12">
                <Card className="card-plain card-blog">
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col md="6">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={
                            require("assets/img/rest/4table.jpg").default
                          }
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <CardBody>
                        <p
                          className="card-description"
                          dangerouslySetInnerHTML={{
                            __html: t("restaurant-desc"),
                          }}
                        />
                        <CardFooter>
                        <h5 className="text-white">{t("restaurant-book-title")}</h5>
                          
                          <Button
                            className="btn-round m-1"
                            color="danger"
                            href="tel:+34973459003"
                          >
                            <i className="fa fa-phone"></i>
                            {t("973 45 90 03")}
                          </Button>
                          <Button

                            className="btn-round m-1"
                            color="danger"
                            href="mailto:reserva@hostalroma.com"
                          >
                            <i className="fa fa-envelope"></i>
                            {t("reserva@hostalroma.com")}
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="title">{t("restaurant-galery-title")}</h3>
              </Col>
            </Row>
            <div
              style={{
                marginTop: 30,
                width: "100%",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <ImgCard img={require("assets/img/dishes/amanida.jpg")}></ImgCard>
              <ImgCard
                img={require("assets/img/dishes/parmentier.jpg")}
              ></ImgCard>
              <ImgCard img={require("assets/img/dishes/wok.jpg")}></ImgCard>
              <ImgCard img={require("assets/img/dishes/arros.jpg")}></ImgCard>
              <ImgCard img={require("assets/img/dishes/xai.jpg")}></ImgCard>
              <ImgCard img={require("assets/img/dishes/bacalla.jpg")}></ImgCard>
              <ImgCard
                img={require("assets/img/dishes/caragols.jpg")}
              ></ImgCard>
              <ImgCard
                img={require("assets/img/dishes/carpaccio.jpg")}
              ></ImgCard>
              <ImgCard
                img={require("assets/img/dishes/carxofes.jpg")}
              ></ImgCard>
              <ImgCard img={require("assets/img/dishes/pastis.jpg")}></ImgCard>
              <ImgCard img={require("assets/img/dishes/tarta.jpg")}></ImgCard>
              <ImgCard img={require("assets/img/dishes/tataki.jpg")}></ImgCard>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Main;

/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";

import style from "../../assets/css/custom.css"
// core components
import { useTranslation } from "react-i18next";
function SectionProject() {
  const [t] = useTranslation();

  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <h2 className="title">{t("landing-aboutUs-title")}</h2>
              <h5 className="description"
              dangerouslySetInnerHTML={{
                    __html: t("landing-aboutUs-desc"),
                  }}/>
              <br />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <h2 className="title">{t("landing-offering-title")}</h2>
              <br />
              <Card className="card-plain card-blog">
                <Row style={{
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"}}
                     className="mobile-reverse"
                >
                  <Col md="6">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/dishes/carxofes.jpg").default}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <CardBody>
                      <h6 className="card-category text-success">
                        <i className="fa fa-cutlery mr-1" />
                        {t("landing-offering-menu-hint")}
                      </h6>
                      <CardTitle tag="h3">
                        {t("landing-offering-menu")}
                      </CardTitle>
                      <p
                        className="card-description"
                        dangerouslySetInnerHTML={{
                          __html: t("landing-offering-menu-desc"),
                        }}
                      />
                      <CardFooter>
                        <Button
                          className="btn-move-right btn-round"
                          color="danger"
                          href="/restaurant#reserva"
                          target="blank"
                        >
                          {t("landing-offering-menu-button")}
                          <i className="nc-icon nc-minimal-right"></i>
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
              <br />
              <br />
              <Card className="card-plain card-blog">
              <Row style={{
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"
                  }}>
                  <Col md="6">
                    <CardBody>
                      <h6 className="card-category text-danger">
                        <i className="fa fa-bed mr-1" />
                        {t("landing-offering-hostal-hint")}
                      </h6>
                      <CardTitle tag="h3">
                        {t("landing-offering-hostal")}
                      </CardTitle>
                      <p
                        className="card-description"
                        dangerouslySetInnerHTML={{
                          __html: t("landing-offering-hostal-desc"),
                        }}
                      />
                      <CardFooter>
                        <Button
                          className="btn-move-right btn-round"
                          color="danger"
                          href="/hostal"
                          target="blank"
                        >
                          {t("landing-offering-hostal-button")}
                          <i className="nc-icon nc-minimal-right"></i>
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Col>
                  <Col md="6">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/rooms/107bed.jpg").default}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
              <br />
              <Card className="card-plain card-blog">
              <Row style={{
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center"}}
                   className="mobile-reverse"
              >
                  <Col md="6">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/rest/orquidees.jpg").default}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <CardBody>
                      <h6 className="card-category text-warning">
                      <i className="fa fa-coffee mr-1" />
                        {t("landing-offering-cafe-hint")}
                      </h6>
                      <CardTitle tag="h3">
                        {t("landing-offering-cafe")}
                      </CardTitle>
                      <p
                        className="card-description"
                        dangerouslySetInnerHTML={{
                          __html: t("landing-offering-cafe-desc"),
                        }}
                      />
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionProject;

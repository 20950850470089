/*eslint-disable*/
import React from "react";
import i18n from "i18next";
import Select from "react-select";
import { useHistory } from "react-router-dom";

// reactstrap components
import {Container, NavItem, NavLink, Row} from "reactstrap";

// core components

function FooterBlack() {
    const history = useHistory();
  const [languageSelect, setLanguageSelect] = React.useState({
    value: i18n.language,
    label: i18n.language == "ca" ? "Català" : (
      i18n.language == "es" ? "🇪🇸 Español" : "🇬🇧 English")
  });

  return (
    <>
      <footer className="footer footer-black">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul style={{height:80}}>
                <li>
                <Select
                  menuPlacement="top"
                  className="react-select react-select-white"
                  classNamePrefix="react-select"
                  styles={{input:()=>({width:100, margin:0, padding:0})}}
                  value={languageSelect}
                  onChange={(value) => {setLanguageSelect(value); i18n.changeLanguage(value.value, () => {
                        const newPath = history.location.pathname.replace(/(\/ca\/|\/es\/|\/en\/)/gm, '/' + value.value + '/')
                          history.push(newPath);
                  })}}
                  options={[
                    { value: "ca", label: "Català" },
                    { value: "en", label: "🇬🇧 English" },
                    { value: "es", label: "🇪🇸 Español" }
                  ]}
                />
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © 2021
                , made with <i className="fa fa-heart heart" /> in Cubells
                <div>Icons by <a href="https://www.flaticon.es/autores/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.es/" title="Flaticon">www.flaticon.es</a></div>
              </span>
                <a href="/sostenibilitat">Sostenibilitat</a>
            </div>
          </Row>

        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;

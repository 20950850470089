import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
// core components

import { useTranslation } from "react-i18next";

function ColorNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState(
    props.fixed === true ? "" : "navbar-transparent"
  );
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [t] = useTranslation();

  React.useEffect(() => {
    if (props.fixed !== true) {
      // initialise
      let headroom = new Headroom(document.getElementById("navbar-main"));

      headroom.init();
    }
    const updateNavbarColor = () => {
      if (
        (document.documentElement.scrollTop > 499 ||
          document.body.scrollTop > 499) &&
        props.fixed !== true
      ) {
        setNavbarColor("");
      } else if (
        (document.documentElement.scrollTop < 500 ||
          document.body.scrollTop < 500) &&
        props.fixed !== true
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  const showBlack =
    props.fixed === true
      ? true
      : document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499;
  return (
    <>
      {props.fixed !== true && bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
        style={{ height: props.fixed === true ? 70 : undefined }}
      >
        <Container>
          <div className="navbar-translate">
            {!showBlack && (
              <NavbarBrand id="navbar-brand" to="/" tag={Link}>
                <img
                  alt="Logo"
                  style={{ width: 150 }}
                  src={require("assets/img/logoWhite.png").default}
                />
              </NavbarBrand>
            )}
            {showBlack && (
              <NavbarBrand id="navbar-brand" to="/" tag={Link}>
                <img
                  alt="Logo"
                  style={{ width: 100 }}
                  src={require("assets/img/logo.png").default}
                />
              </NavbarBrand>
            )}
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={props.fixed === true ? true : collapseOpen}>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/carta">{t("navbar-cartabutton")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://direct-book.com/properties/hostalrestauranteromadirect"
                  target="_blank"
                  rel="noopener"
                >
                  {t("landing-bookhostal-button")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/restaurant">{t("navbar-restaurant")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/hostal">{t("navbar-hostal")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="tel:+34973459003" target="_blank" rel="noopener">
                  <i className="fa fa-phone ml-1" /> {t("973 45 90 03")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  data-placement="bottom"
                  href="https://www.instagram.com/roma_cubells/"
                  target="_blank"
                  rel="noopener"
                  title={t("navbar-followInstagram")}
                >
                  <i className="fa fa-instagram" />
                  <p className="d-lg-none">{t("Instagram")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  data-placement="bottom"
                  href="https://www.facebook.com/hostalrestaurantroma/"
                  target="_blank"
                  rel="noopener"
                  title={t("navbar-followFacebook")}
                >
                  <i className="fa fa-facebook-square " />
                  <p className="d-lg-none">{t("Facebook")}</p>
                </NavLink>
              </NavItem>
              {/*<NavItem>*/}
              {/*  <Button*/}
              {/*      className="btn-round"*/}
              {/*      color="success"*/}
              {/*      href="/nadal"*/}
              {/*  >*/}
              {/*    <i className="fas fa-tree mr-2"/>*/}
              {/*    {t("navbar-nadal")}*/}
              {/*  </Button>*/}
              {/*</NavItem>*/}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;

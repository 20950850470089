/*!

=========================================================
* Paper Kit PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch, useParams } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/custom.css";
// pages
import "translations"
import Index from "views/landing";
import Restaurant from "views/restaurant";
import Hostal from "views/hostal";
import Carta from "views/carta";
import PdfRenderer from "views/pdf";
import i18n from "i18next";
import Sostenibilitat from "./views/sostenibilitat";
// others

function App() {
    const { lang } = useParams()
    if (i18n.language !== lang && (lang === 'ca' || lang === 'es' || lang === 'en')) {
        i18n.changeLanguage(lang)
    }
    return <Switch>
        <Route path="/restaurant" render={(props) => <Restaurant {...props} />} />
        <Route path="/sostenibilitat" render={(props) => <Sostenibilitat {...props} />} />
        <Route path="/hostal" render={(props) => <Hostal {...props} />} />
        <Route path="/carta" render={(props) => <PdfRenderer selected={"carta"} {...props} />} />
        <Route path="/menu" render={(props) => <PdfRenderer selected={"menu"} {...props} />} />
        <Route path="/la-carta" render={(props) => <PdfRenderer selected={"carta"} {...props} />} />
        <Route path="/cafe" render={(props) => <PdfRenderer selected={"cafe"} {...props} />} />
        <Route path="/bodega" render={(props) => <PdfRenderer selected={"bodega"} {...props} />} />
        <Route path="/festa-major" render={(props) => <PdfRenderer selected={"festaMajor"} {...props} />} />
        {/*<Route path="/nadal" render={(props) => <PdfRenderer nadal file={"https://firebasestorage.googleapis.com/v0/b/hostalromaweb.appspot.com/o/roma-emportarnadal21.pdf?alt=media&token=82542d81-3672-4a0b-bff4-4d8c16686e8c"} {...props} />} />*/}
        <Route path="/" render={(props) => <Index {...props} />} />
    </Switch>
}

function RedirectApp({ location }) {
    const { lang } = useParams()
    if (lang === undefined) {
        return <Redirect to={'/ca/'} />
    }
    return <Redirect to={location.pathname.replace(/(\/ca\/|\/es\/|\/en\/)/gm, '/' + i18n.language + '/')} />
}

ReactDOM.render(
    <BrowserRouter>
        <Route exact path="/" render={({ location }) => <RedirectApp location={location} />} />
        <Route path="/:lang/" children={<App />} />
    </BrowserRouter>,
    document.getElementById("root")
);

import React from "react";
import {useTranslation} from "react-i18next";

// reactstrap components
import {Button, Card, CardBody, CardFooter, Col, Container, Row,} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

const ImgCard = ({img}) => (
    <Card style={{flex: 3, flexBasis: 350}} className="m-2 ">
        <img
            style={{borderRadius: 10}}
            alt="..."
            className="img"
            src={img.default}
        />
    </Card>
);

function Main() {
    const [startDate, setStartDate] = React.useState(new Date());
    const [t] = useTranslation();

    return (
        <>
            <div className="main">
                <div className="section section-dark">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">{t("sustainability_title")}</h2>
                            </Col>
                            <Col className="ml-auto mr-auto " md="12">
                                <Card className="card-plain card-blog">
                                    <Row
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >

                                        <Col md="6">
                                            <CardBody>
                                                <p
                                                    style={{
                                                        textAlign: "justify",
                                                    }}
                                                    className="card-description"
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("sustainability_desc"),
                                                    }}
                                                />

                                            </CardBody>
                                        </Col>


                                    </Row>
                                    <Row
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Col md="6">
                                            <CardBody>
                                                <p
                                                    style={{
                                                        textAlign: "justify",
                                                    }}
                                                    className="card-description"
                                                    dangerouslySetInnerHTML={{
                                                        __html: t("sustainability_desc2"),
                                                    }}
                                                />

                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>

                            <img
                                style={{width: 600,}}
                                alt="..."
                                className="img"
                                src={require("assets/img/banner_autoconsum.png").default}
                            />
                        </Row>
                    </Container>

                </div>
            </div>
        </>
    );
}

export default Main;

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const navbar = {
  ca: {
    "navbar-title": "Hostal Roma",
    "navbar-followTwitter": "Segueix-nos a Twitter",
    "navbar-followFacebook": "Segueix-nos a Facebook",
    "navbar-followInstagram": "Segueix-nos a Instagram",
    "navbar-actionCall": "Reserva online",
    "navbar-cartabutton": "Carta online",
    "navbar-hostal": "Hostal",
    "navbar-historia": "Història",
    "navbar-cafeteria": "Cafeteria",
    "navbar-restaurant": "Restaurant",
    "navbar-nadal": "Especial nadal!",
    "nadal_button": "Menjar de nadal per emportar",
  },
  es: {
    "navbar-title": "Hostal Roma",
    "navbar-followTwitter": "Siguenos en Twitter",
    "navbar-followFacebook": "Siguenos en Facebook",
    "navbar-followInstagram": "Siguenos en Instagram",
    "navbar-actionCall": "Reserva online",
    "navbar-cartabutton": "Carta online",
    "navbar-hostal": "Hostal",
    "navbar-historia": "History",
    "navbar-cafeteria": "Cafeteria",
    "navbar-restaurant": "Restaurante",
    "navbar-nadal": "Especial navidad!",
    "nadal_button": "Comida de Navidad para llevar",
  },
  en: {
    "navbar-title": "Hostal Roma",
    "navbar-followTwitter": "Follow us on Twitter",
    "navbar-followFacebook": "Follow us on Facebook",
    "navbar-followInstagram": "Follow us on Instagram",
    "navbar-actionCall": "Book online",
    "navbar-cartabutton": "Online menu",
    "navbar-hostal": "Hostal",
    "navbar-historia": "History",
    "navbar-cafeteria": "Cafeteria",
    "navbar-restaurant": "Restaurant",
    "navbar-nadal": "Christmas special!",
    "nadal_button": "Take away Christmas"
  },
};


const schduleTimes = {
    monday: '07:00 - 17:00',
    tuesday: '-',
    wednesday: '07:00 - 23:00',
    thursday: '07:00 - 23:00',
    friday: '07:00 - 00:00',
    saturday: '08:00 - 00:00',
    sunday: '08:00 - 22:00',
}

const schedule = {
    monday : ` </td> <td> ${schduleTimes.monday}</td> </tr>`,
    tuesday : ` </td> <td> ${schduleTimes.tuesday} </td> </tr>`,
    wednesday : ` </td> <td> ${schduleTimes.wednesday} </td> </tr>`,
    thursday : ` </td> <td> ${schduleTimes.thursday} </td> </tr>`,
    friday : ` </td> <td> ${schduleTimes.friday} </td> </tr>`,
    saturday : ` </td> <td> ${schduleTimes.saturday} </td> </tr>`,
    sunday : ` </td> <td> ${schduleTimes.sunday} </td> </tr>`,

}

const landing = {
  ca: {
    "landing-title1": "Benvinguts",
    "landing-desc1": "Hostal Restaurant a Cubells",
    "landing-bookhostal-button": "Reservar habitació",
    "landing-online-menubutton": "Carta online",
    "landing-offering-title": "La nostra oferta",
    "landing-offering-desc": "Us oferim un servei de restauració, hostalatge i cafeteria.",
    "landing-offering-menu": "Menú diari i de cap de setmana",
    "landing-offering-menu-hint": "Restaurant",
    "landing-offering-menu-button": "Anar al restaurant",
    "landing-offering-menu-desc": `
        Us proposem un extens menú renovat diàriament amb excel·lents productes de proximitat i el compromís d'oferir (com a mínim) una opció vegana de primer, segon i postres.
        <br/><br/> A més a més, us podem dissenyar la vostra celebració a mida.
    `, 
    "landing-offering-hostal": "Habitacions",
    "landing-offering-hostal-hint": "Hostal",
    "landing-offering-hostal-button": "Descobreix l'hostal",
    "landing-offering-hostal-desc": `
        Disposem de 7 habitacions acollidores. Gaudireu d'un tracte de proximitat en un entorn rural on la llista d'activitats i llocs per descobrir no s'esgota...
        <br/><br/> També podeu reservar online la vostra estada, directament amb nosaltres. I si, al millor preu.
    `,
    "landing-offering-cafe": "Servei de cafeteria i bar",
    "landing-offering-cafe-hint": "Cafeteria",
    "landing-offering-cafe-button": "Veure la cafeteria",
    "landing-offering-cafe-desc": "Tant si voleu fer un mos ràpid durant un viatge de carretera, un esmorzar de forquilla i ganivet o un beure refrescant, us tenim coberts!",
    "landing-contact-title": "Contacta amb nosaltres",
    "landing-aboutUs-title": "Descobreix-nos",
    "landing-aboutUs-desc": `En una situació privilegiada. Elevat sobre un turó. Envoltat de natura. 
        <br/>L'Hostal Restaurant Roma és un espai on la tradició i creativitat es conjuguen per oferir una singular cuina catalana. 
        <br/>La qualitat en matèria primera, les seves receptes i l'atenció de l’equip són els pilars de la casa.
        <br/><br/>Fidels a l’herència d’un saber fer que data des de 1957, la tercera generació continua el mateix camí.`,
    "landing-schedule-title": "Horaris",
    "landing-schedule-desc": `
        <table style="margin:0px auto;">
        <colgroup>
        <col style="width: 120px">
        <col style="width: 120px">
        </colgroup>
        <tbody style="text-align:left;padding:10px"> 
        <tr> <td> <b>Dilluns</b>: ${schedule.monday}
        <tr> <td> <b>Dimarts</b>: ${schedule.tuesday}
        <tr> <td> <b>Dimecres</b>: ${schedule.wednesday}
        <tr> <td> <b>Dijous</b>: ${schedule.thursday}
        <tr> <td> <b>Divendres</b>: ${schedule.friday}
        <tr> <td> <b>Dissabte</b>: ${schedule.saturday}
        <tr> <td> <b>Diumenge</b>: ${schedule.sunday}
        </tbody>
        </table>
    `,
    "landing-contactPhone-title": "Contacte",
    "landing-contactPhone-desc": `
        <b>973 45 90 03 <br/>
        973 45 90 76</b> <br/>
    `,
    "landing-contactPhone-button": "Trucar ara",
    "landing-contactAddress-title": "Adreça",
    "landing-contactAddress-desc": `
      Carretera C-26 Km 40 <br/>
      25737 Cubells <br/>
      Lleida <br/>
    `,
    "landing-contactAddress-button": "Direccions",
  },
  es: {
    "landing-title1": "Bienvenidos",
    "landing-desc1": "Hostal Restaurante en Cubells",
    "landing-bookhostal-button": "Reservar habitación",
    "landing-online-menubutton": "Carta en línea",
    "landing-offering-title": "Nuestra oferta",
    "landing-offering-desc": "Ofrecemos un servicio de restauración, hospedaje y cafetería.",
    "landing-offering-menu": "Menú diario y de fin de semana",
    "landing-offering-menu-hint": "Restaurante",
    "landing-offering-menu-button": "Ir al restaurante",
    "landing-offering-menu-desc": `
        Os proponemos un extenso menú renovado diariamente con excelentes productos de proximidad y el compromiso de ofrecer (como mínimo) una opción vegana de primer, segundo y postre.
        <br/> Además, podemos diseñar su celebración a medida.
    `,
    "landing-offering-hostal": "Habitaciones",
    "landing-offering-hostal-hint": "Hostal",
    "landing-offering-hostal-button": "Descubre el hostal",
    "landing-offering-hostal-desc": `
        Disponemos de 7 habitaciones acogedores. Disfrutará de un trato de proximidad en un entorno rural donde la lista de actividades y lugares para descubrir no se agota ...
        <br/> También puede reservar en línea su estancia, directamente con nosotros. Y si, al mejor precio.
    `,
    "landing-offering-cafe": "Servicio de cafetería y bar",
    "landing-offering-cafe-hint": "Cafetería",
    "landing-offering-cafe-button": "Ver la cafetería",
    "landing-offering-cafe-desc": "Tanto si desea comer algo rápido durante un viaje de carretera, un desayuno de tenedor y cuchillo o una bebida refrescante, os tenemos cubiertos!",
    "landing-contact-title": "Contacta con nosotros",
    "landing-aboutUs-title": "Descúbrenos",
    "landing-aboutUs-desc": `En una situación privilegiada. Elevado sobre una colina. Rodeado de naturaleza.
        <br/> El Hostal Restaurante Roma es un espacio donde la tradición y creatividad se conjuga para ofrecer una cocina catalana única.
        <br/> La calidad en materia prima, sus recetas y la atención del equipo son los pilares de la casa.
        <br/> Fieles a la herencia de un saber hacer que data desde 1957, la tercera generación continúa el mismo camí.`,
    "landing-schedule-title": "Horarios",
    "landing-schedule-desc": `
        <Table style = "margin: 0px auto;">
        <Grupo>
        <Col style = "width: 120px">
        <Col style = "width: 120px">
        </ Colgroup>
        <Tbody style = "text-align: left; padding: 10px">
        <Tr> <td> <b> Lunes </ b>: ${schedule.monday}
        <Tr> <td> <b> Martes </ b>: ${schedule.tuesday}
        <Tr> <td> <b> Miércoles </ b>: ${schedule.wednesday}
        <Tr> <td> <b> Jueves </ b>: ${schedule.thursday}
        <Tr> <td> <b> Viernes </ b>: ${schedule.friday}
        <Tr> <td> <b> Sábado </ b>: ${schedule.saturday}
        <Tr> <td> <b> Domingo </ b>: ${schedule.sunday}
        </ Tbody>
        </ Table>
    `,
    "landing-contactPhone-title": "Contacto",
    "landing-contactPhone-desc": `
        <B> 973 45 90 03 <br/>
        973 45 90 76 </ b> <br/>
    `,
    "landing-contactPhone-button": "Llamar ahora",
    "landing-contactAddress-title": "Dirección",
    "landing-contactAddress-desc": `
      Carretera C-26 Km 40 <br/>
      25737 Cubos <br/>
      Lleida <br/>
    `,
    "landing-contactAddress-button": "Direcciones",
  },
  en: {
    "landing-title1": "Welcome",
    "landing-desc1": "Hostel Restaurant in Cubells",
    "landing-bookhostal-button": "Book a room",
    "landing-online-menubutton": "Online menu",
    "landing-offering-title": "Our offer",
    "landing-offering-desc": "We offer restaurant, accommodation and cafeteria service.",
    "landing-offering-menu": "Daily and weekend menu",
    "landing-offering-menu-hint": "Restaurant",
    "landing-offering-menu-button": "Go to the restaurant",
    "landing-offering-menu-desc": `
        We offer an extensive menu renewed daily with excellent local products and the commitment to offer (at least) a vegan option of first, second and dessert.
        <br/> <br/> In addition, we can customize your celebration.
    `,
    "landing-offering-hostal": "Rooms",
    "landing-offering-hostal-hint": "Hostel",
    "landing-offering-hostal-button": "Discover the hostel",
    "landing-offering-hostal-desc": `
    We have 7 cozy rooms. You will enjoy a close treatment in a rural setting where the list of activities and places to discover are never ending...
        <br/> <br/> You can also book your stay online, directly with us. And yes, at the best price.
    `,
    "landing-offering-cafe": "Cafeteria and bar service",
    "landing-offering-cafe-hint": "Cafeteria",
    "landing-offering-cafe-button": "See the cafeteria",
    "landing-offering-cafe-desc": "Whether you want to have a quick bite during a road trip, a fork and knife breakfast or a refreshing drink, we have you covered!",
    "landing-contact-title": "Contact us",
    "landing-aboutUs-title": "Discover us",
    "landing-aboutUs-desc": `In a privileged situation. Elevated on a hill. Surrounded by nature.
        <br/> Hostal Restaurant Roma is a space where tradition and creativity combine to offer a unique Catalan cuisine.
        <br/> Quality in raw materials, their recipes and the attention of the team are the pillars of the house.
        <br/> <br/> True to the legacy of know-how dating back to 1957, the third generation continues on the same path.
        `,
    "landing-schedule-title": "Schedule",
    "landing-schedule-desc": `
        <table style = "margin: 0px auto;">
        <group>
        <col style = "width: 120px">
        <col style = "width: 120px">
        </colgroup>
        <tbody style = "text-align: left; padding: 10px">
        <tr> <td> <b> Monday </b>: ${schedule.monday}
        <tr> <td> <b> Tuesday </b>: ${schedule.tuesday}
        <tr> <td> <b> Wednesday </b>: ${schedule.wednesday}
        <tr> <td> <b> Thursday </b>: ${schedule.thursday}
        <tr> <td> <b> Friday </b>: ${schedule.friday}
        <tr> <td> <b> Saturday </b>: ${schedule.saturday}
        <tr> <td> <b> Sunday </b>: ${schedule.sunday}
        </tbody>
        </table>
    `,
    "landing-contactPhone-title": "Contact",
    "landing-contactPhone-desc": `
        <b> 973 45 90 03 <br/>
        973 45 90 76 </b> <br/>
    `,
    "landing-contactPhone-button": "Call now",
    "landing-contactAddress-title": "Address",
    "landing-contactAddress-desc": `
      Road C-26 Km 40 <br/>
      25737 Cubells <br/>
      Lleida <br/>
    `,
    "landing-contactAddress-button": "Directions",
  }
};

const restaurant = {
  ca: {
    sustainability_title: "Compromis sostenible",
    sustainability_desc: "Al nostre restaurant, la passió pel medi ambient va més enllà del plat. No només ens esforcem per mantenir pràctiques sostenibles, sinó que estem en un procés constant d'innovació i millora. Amb l'aspiració d'edificar un futur més verd i responsable, estem continuament buscant i adoptant noves accions que minimitzin la nostra petjada ecològica. No és una tasca completada, sinó una jornada de compromís diari amb el nostre planeta.",
    sustainability_desc2: `
    <ul>
    <li><strong>Energia Verda:</strong> Les nostres instal·lacions funcionen amb plaques solars, reduint la nostra dependència dels combustibles fòssils.</li>
    <li><strong>Il·luminació Eficient:</strong> Gràcies a l'ús exclusiu de llums LED, reduïm el nostre consum energètic.</li>
    <li><strong>Sense Plàstics d'Ús Únic:</strong> Hem eliminat completament els plàstics d'un sol ús.</li>
    <li><strong>Productes Logcals:</strong> Prioritzem productors de proximitat.</li>
    <li><strong>Opcions Conscients:</strong> El nostre menú inclou delicioses opcions vegetarianes i veganes, reconeixent la importància d'una dieta sostenible.</li>
    <li><strong>Gestió de Residus:</strong> Ens comprometem amb el reciclatge i una correcta disposició dels nostres residus.</li>
    <li><strong>Més Aigua, Menys Residus:</strong> Hem eliminat l'aigua embotellada, fomentant una opció més sostenible.</li>
</ul>`,
    "restaurant-title" : "Restaurant",
    "restaurant-galery-title" : "Galeria d'imatges",
    "restaurant-desc" : `Oferim una cuina mediterrània catalana a la qual intentem aportar innovacions. Confeccionem tots els plats a la nostra cuina, seguint receptes úniques treballant amb excel·lents matèries primeres de productors de km-0 i/o ecològiques.
    </br> </br> La nostra cuina ha heretat l’experiència i els secrets de l'àvia, on hem sabut combinar la delicada elaboració dels plats més tradicionals com els caragols a la llauna o la perdiu a la vinagreta, amb les tendències culinàries del nostre temps.
    </br> </br> `,
    "restaurant-book-title" : "Fes la teva reserva",
    "restaurant-book-form-name" : "Nom de la reserva",
    "restaurant-book-form-email" : "Correu electrònic",
  },
  es: {
    sustainability_title: "Compromis sostenible",
    sustainability_desc: "Al nostre restaurant, la passió pel medi ambient va més enllà del plat. No només ens esforcem per mantenir pràctiques sostenibles, sinó que estem en un procés constant d'innovació i millora. Amb l'aspiració d'edificar un futur més verd i responsable, estem continuament buscant i adoptant noves accions que minimitzin la nostra petjada ecològica. No és una tasca completada, sinó una jornada de compromís diari amb el nostre planeta.",
    sustainability_desc2: `
   <ul>
    <li><strong>Energia Verda:</strong> Les nostres instal·lacions funcionen amb plaques solars, reduint la nostra dependència dels combustibles fòssils.</li>
    <li><strong>Il·luminació Eficient:</strong> Gràcies a l'ús exclusiu de llums LED, reduïm el nostre consum energètic.</li>
    <li><strong>Sense Plàstics d'Ús Únic:</strong> Hem eliminat completament els plàstics d'un sol ús.</li>
    <li><strong>Productes Locals:</strong> Prioritzem productors de proximitat.</li>
    <li><strong>Opcions Conscients:</strong> El nostre menú inclou delicioses opcions vegetarianes i veganes, reconeixent la importància d'una dieta sostenible.</li>
    <li><strong>Gestió de Residus:</strong> Ens comprometem amb el reciclatge i una correcta disposició dels nostres residus.</li>
    <li><strong>Més Aigua, Menys Residus:</strong> Hem eliminat l'aigua embotellada, fomentant una opció més sostenible.</li>
</ul>`,
    "restaurant-title": "Restaurante",
    "restaurant-galery-title": "Galería de imágenes",
    "restaurant-desc": `Ofrecemos una cocina mediterránea catalana a la que intentamos aportar innovaciones. Confeccionamos todos los platos en nuestra cocina, siguiendo recetas únicas trabajando con excelentes materias primas de productores de Km-0 y / o ecológicas.
     </br> </br> Nuestra cocina ha heredado la experiencia y los secretos de la abuela, donde hemos sabido combinar la delicada elaboración de los platos más tradicionales como los caracoles a la lata o la perdiz a la vinagreta, con las tendencias culinarias de nuestro tiempo.
     </br> </br> `,
    "restaurant-book-title": "Haz tu reserva",
    "restaurant-book-form-name": "Nombre de la reserva",
    "restaurant-book-form-email": "Correo electrónico",
  },
  en: {
    sustainability_title: "Compromis sostenible",
    sustainability_desc: "Al nostre restaurant, la passió pel medi ambient va més enllà del plat. No només ens esforcem per mantenir pràctiques sostenibles, sinó que estem en un procés constant d'innovació i millora. Amb l'aspiració d'edificar un futur més verd i responsable, estem continuament buscant i adoptant noves accions que minimitzin la nostra petjada ecològica. No és una tasca completada, sinó una jornada de compromís diari amb el nostre planeta.",
    sustainability_desc2: `
    <ul>
    <li><strong>Energia Verda:</strong> Les nostres instal·lacions funcionen amb plaques solars, reduint la nostra dependència dels combustibles fòssils.</li>
    <li><strong>Il·luminació Eficient:</strong> Gràcies a l'ús exclusiu de llums LED, reduïm el nostre consum energètic.</li>
    <li><strong>Sense Plàstics d'Ús Únic:</strong> Hem eliminat completament els plàstics d'un sol ús.</li>
    <li><strong>Productes Locals:</strong> Prioritzem productors de proximitat.</li>
    <li><strong>Opcions Conscients:</strong> El nostre menú inclou delicioses opcions vegetarianes i veganes, reconeixent la importància d'una dieta sostenible.</li>
    <li><strong>Gestió de Residus:</strong> Ens comprometem amb el reciclatge i una correcta disposició dels nostres residus.</li>
    <li><strong>Més Aigua, Menys Residus:</strong> Hem eliminat l'aigua embotellada, fomentant una opció més sostenible.</li>
</ul> `,
    "restaurant-title": "Restaurant",
     "restaurant-galery-title": "Image Gallery",
     "restaurant-desc": `We offer Catalan Mediterranean cuisine to which we try to bring innovations. We make all the dishes in our kitchen, following unique recipes working with excellent raw materials from km-0 and / or organic producers.
     </br> </br> Our kitchen has inherited the experience and secrets of the grandmother, where we have been able to combine the delicate elaboration of the most traditional dishes such as canned snails or partridge in vinaigrette, with the culinary trends of our time.
     </br> </br> `,
     "restaurant-book-title": "Make your reservation",
     "restaurant-book-form-name": "Reservation name",
     "restaurant-book-form-email": "Email",
  },
}

const hostal = {
  ca: {
    "hostal-title" : "Hostal Roma",
    "hostal-desc" : `
    Gaudeix de la seva estada a Cubells, situat al peu del Montsec i a pocs quilòmetres del pantà de Camarasa, el de Sant Llorenç de Montgai i el de Rialp.
    </br> Podeu trobar una gran varietat d'activitats a realitzar a les webs de l'<a href="http://cubells.cat/ca/administracio/cubells/patrimoni-historic/t-2265" title="Ajuntament de Cubells">Ajuntament de Cubells</a> i d'<a href="https://guiaactivitats.aralleida.cat/en" title="Ara Lleida">Ara Lleida</a>
    </br> </br> Totes les habitacions consten de servei de neteja diari, calefacció, aire condicionat i bomba de calor, WiFi, televisió, bany tipus suite, articles de tocador gratuïts, tovalloles i llençols. A més a més, acceptem gossos 🐶!
    `,
    "hostal-single-title" : "Habitació individual",
    "hostal-double-title" : "Habitació doble",
    "hostal-triple-title" : "Habitació triple",
    "hostal-single-desc" : "Habitació amb llit d’1,05 m amb finestra exterior, bany propi i vistes a la muntanya.",
    "hostal-double-desc": "Habitació amb llit d’1,8 m amb finestra exterior o interior, bany propi i tranquilitat.",
    "hostal-triple-desc" : "Amb opció de llit individual i 1 llit doble o bé 3 llits individuals",
    "hostal-book-title" : "Fes la teva reserva",
    "hostal-book-desc" : "",
    "hostal-book-button" : "Fer una reserva",
    "restaurant-book-form-phone" : "Número de mòbil",
    "restaurant-book-form-name" : "Nom de la reserva",
    "restaurant-book-form-email" : "Correu electrònic",
  },
  es: {
    "hostal-title": "Hostal Roma",
    "hostal-desc": `
    Disfruta de su estancia en Cubos, situado al pie del Montsec y a pocos kilómetros del pantano de Camarasa, el de Sant Llorenç de Montgai y el de Rialp.
    </br> Puede encontrar una gran variedad de actividades a realizar en las webs de la <a href = "http://cubells.cat/ca/administracio/cubells/patrimoni-historic/t-2265" title = " Ayuntamiento de Cubells "> Ayuntamiento de Cubells </a> y de <a href="https://guiaactivitats.aralleida.cat/en" title="Ara Lleida"> Ara Lleida </a>
    </br> </br> Todas las habitaciones constan de servicio de limpieza diario, calefacción, aire acondicionado y bomba de calor, WiFi, televisión, baño tipo suite, artículos de aseo, toallas y sábanas. Además, aceptamos perros 🐶!
    `,
    "hostal-single-title": "Habitación individual",
    "hostal-double-title": "Habitación doble",
    "hostal-triple-title": "Habitación triple",
    "hostal-single-desc": "Habitación con cama de 1,05 m con ventana exterior, baño propio y vistas a la montaña.",
    "hostal-double-desc": "Habitación con cama de 1,80 m con ventana exterior o interior, baño propio y tranquilidad.",
    "hostal-triple-desc": "Con opción de cama individual y 1 cama doble o bien 3 camas individuales",
    "hostal-book-title": "Haz tu reserva",
    "hostal-book-desc": "",
    "hostal-book-button": "Hacer una reserva",
    "restaurante-book-form-phone": "Número de móvil",
    "restaurante-book-form-name": "Nombre de la reserva",
    "restaurante-book-form-email": "Correo electrónico",
  },
  en: {
    "hostal-title": "Hostal Roma",
    "hostal-desc": `
    Enjoy your stay in Cubells, located at the foot of Montsec and a few kilometers from the Camarasa reservoir, Sant Llorenç de Montgai and Rialp.
    </br> You can find a wide variety of activities to do on the websites of the <a href = "http://cubells.cat/ca/administracio/cubells/patrimoni-historic/t-2265" title = " Cubells City Council "> Cubells City Council </a> and <a href="https://guiaactivitats.aralleida.cat/en" title="Ara Lleida"> Ara Lleida </a>
    </br> </br> All rooms have daily housekeeping, heating, air conditioning and heat pump, WiFi, TV, en suite bathroom, free toiletries, towels and sheets. Plus, we accept dogs 🐶!
    `,
    "hostal-single-title": "Single room",
    "hostal-double-title": "Double Room",
    "hostal-triple-title": "Triple room",
    "hostal-single-desc": "Room with a 1.05 m bed with an outside window, a private bathroom and mountain views.",
    "hostal-double-desc": "Room with a 1.80 m bed with an outdoor or indoor facing window, a private bathroom and calm.",
    "hostal-triple-desc": "With option of single bed and 1 double bed or 3 single beds",
    "hostal-book-title": "Make your reservation",
    "hostal-book-desc": "",
    "hostal-book-button": "Make a reservation",


    "restaurant-book-form-phone": "Mobile number",
    "restaurant-book-form-name": "Reservation name",
    "restaurant-book-form-email": "Email",
  },
}
const onlineMenu = {
  ca: {
    "carta-title": "Carta online",
    "carta-desc": "",

    "carta-festa-major-title": "Menú de Festa Major",
    "carta-festa-major-desc": "",
    "carta-festa-major-button": "Veure",

    "carta-menu-title": "Menú del dia",
    "carta-menu-desc": "",
    "carta-menu-button": "Veure",

    "carta-carta-title": "La carta",
    "carta-carta-desc": "",
    "carta-carta-button": "Veure",

    "carta-winery-title": "Carta de vins",
    "carta-winery-desc": "",
    "carta-winery-button": "Veure",

    "carta-cafe-title": "Entrepans i plats combinats",
    "carta-cafe-desc": "",
    "carta-cafe-button": "Veure",

    "carta-takeaway-title": "Per emportar",
    "carta-takeaway-desc": "",
    "carta-takeaway-button": "Veure",
    "carta-takeaway-fileurl": "",

    "carta-nadal-takeaway-title": "Nadal per emportar",
    "carta-nadal-takeaway-desc": "",
    "carta-nadal-takeaway-button": "Veure",
    "carta-nadal-takeaway-fileurl": "",

  },
  es: {
    "carta-title": "Carta online",
    "carta-desc": "",

    "carta-festa-major-title": "Menú de Fiesta Mayor",
    "carta-festa-major-desc": "",
    "carta-festa-major-button": "Ver",

    "carta-carta-title": "La carta",
    "carta-carta-desc": "",
    "carta-carta-button": "Ver",

    "carta-menu-title": "Menú del día",
    "carta-menu-desc": "",
    "carta-menu-button": "Ver",
    "carta-winery-title": "Carta de vinos",
    "carta-winery-desc": "",
    "carta-winery-button": "Ver",
    "carta-cafe-title": "Bocadillos y platos combinados",
    "carta-cafe-desc": "",
    "carta-cafe-button": "Ver",
    "carta-takeaway-title": "Para llevar",
    "carta-takeaway-desc": "",
    "carta-takeaway-button": "Ver",
    "carta-takeaway-fileurl": "",

    "carta-nadal-takeaway-title": "Navidad para llevar",
    "carta-nadal-takeaway-desc": "",
    "carta-nadal-takeaway-button": "Ver",
    "carta-nadal-takeaway-fileurl": "",
  },
  en: {
      "card-title": "Online menu",
     "letter-desc": "",

      "carta-festa-major-title": "Special festivity menu",
      "carta-festa-major-desc": "",
      "carta-festa-major-button": "View",


    "menu-title-title": "Menu of the day",
     "menu-menu-desc": "",
     "menu-button-button": "View",

     "carta-winery-title": "Wine list",
     "letter-winery-desc": "",
     "letter-winery-button": "View",

     "carta-cafe-title": "Sandwiches and mixed dishes",
     "carte-cafe-desc": "",
     "carta-cafe-button": "View",

     "letter-takeaway-title": "To take away",
     "letter-takeaway-desc": "",
     "letter-takeaway-button": "View",
     "carta-takeaway-fileurl": "",

    "carta-nadal-takeaway-title": "Take away Christmas meals",
    "carta-nadal-takeaway-desc": "",
    "carta-nadal-takeaway-button": "Ver",
    "carta-nadal-takeaway-fileurl": "",
  },
};

const altImages = {
  ca: {
    img_alt_carpaccio: "Carpaccio de vedella amb formatge, amanida i anous",
    img_alt_carxofes: "Carxofes amb una picada de verdures",
    img_alt_amanida: "Amanida amb taronja i esqueixada de bacallà",
    img_alt_arros:
      "Arròs negre de tinta de sèpia decorat amb un calamar i una picada d'all i julivert, decoració amb flors comestibles i allioli.",
    img_alt_bacalla:
      "Bacallà Skrei al forn amb pinyons i panses. Decorat amb espinacs i espàrrecs verds",
    img_alt_caragols: "Caragols a la cassola amb espècies del montsec",
    img_alt_parmentier:
      "Parmentier de patata amb tòfona ous poché i pernil ibèric",
    img_alt_pastis: "Pastís de formatge amb decoració",
    img_alt_patates: "Patates braves del Roma.",
    img_alt_tarta: "",
    img_alt_tataki:
      "Tataki de tonyina, decoració de tomàquets xerri i pebrots del padró ",
    img_alt_wok: "",
    img_alt_xai:
      "Cuixa de xai ecològic al forn amb una picada d'all i julivert. Decorat amb una torreta de patates i xampinyons.",
  },
  es: {
    img_alt_carpaccio: "Carpaccio de ternera con queso, ensalada y nueces",
    img_alt_carxofes: "Alcachofas con una picada de verduras",
    img_alt_amanida: "Ensalada con naranja y esqueixada de bacalao",
    img_alt_arros:
      "Arroz negro de tinta de sepia decorado con un calamar y una picada de ajo y perejil, decoración con flores comestibles y alioli.",
    img_alt_bacalla:
      "Bacalao Skrei al horno con piñones y pasas. Decorado con espinacas y espárragos verdes",
    img_alt_caragols: "Caracoles a la cazuela con especies del montsec",
    img_alt_parmentier:
      "Parmentier de patata con trufa huevos poché y jamón ibérico",
    img_alt_pastis: "Pastel de queso con decoración",
    img_alt_patates: "Patatas bravas del Roma.",
    img_alt_tarta: "",
    img_alt_tataki:
      "Tataki atún, decoración de tomates cherry y pimientos del padrón",
    img_alt_wok: "",
    img_alt_xai:
      "Pierna de cordero ecológico al horno con una picada de ajo y perejil. Decorado con una torreta de patatas y champiñones. ",
  },
  en: {
    img_alt_carpaccio: "Beef carpaccio with cheese, salad and nuts",
    img_alt_carxofes: "Artichokes with chopped vegetables",
    img_alt_amanida: "Salad with orange and sliced cod",
    img_alt_arros:
      "Black sepia ink rice decorated with a squid and chopped garlic and parsley, decorated with edible flowers and allioli.",
    img_alt_bacalla:
      "Baked Skrei cod with pine nuts and raisins. Garnished with spinach and green asparagus",
    img_alt_caragols: "Snails in a casserole with spices from the montsec",
    img_alt_parmentier:
      "Potato parmentier with truffle poached eggs and Iberian ham",
    img_alt_pastis: "Cheescake, decorated with cherries",
    img_alt_patates: "Brave potatoes from Roma",
    img_alt_tarta: "",
    img_alt_tataki:
      "Tuna tataki, decoration of cherry tomatoes and standard peppers",
    img_alt_wok: "",
    img_alt_xai:
      "Baked organic lamb thigh with chopped garlic and parsley. Decorated with a turret of potatoes and mushrooms. ",
  },
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      ca: {
        translation: {
          ...navbar.ca,
          ...landing.ca,
          ...onlineMenu.ca,
          ...altImages.ca,
          ...restaurant.ca,
          ...hostal.ca,
        },
      },
      es: {
        translation: {
          ...navbar.es,
          ...landing.es,
          ...onlineMenu.es,
          ...altImages.es,
          ...restaurant.es,
          ...hostal.es,
        },
      },
      en: {
        translation: {
          ...navbar.en,
          ...landing.en,
          ...onlineMenu.en,
          ...altImages.en,
          ...restaurant.en,
          ...hostal.en,
          previous: "Previous",
          next: "Next",
        },
      },
    },
    fallbackLng: "ca",
    supportedLngs: ["ca", "en", "es"],
    interpolation: {
      escapeValue: false,
    },
  });
